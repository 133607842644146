import React from 'react';
import Nav from '../Nav';
import Header from './Header';
import Jobs from './Jobs';
import LatestJobs from './LatestJobs';
import WhyUs from './WhyUs';
import Statics from './Statics';
import Partners from './Partners';
import Footer from '../Footer';
import SocialBtns from '../SocialBtns';

const Home = () => {
  return (
    <>
        <Nav />
        <Header />
        <SocialBtns />
        <Jobs />
        <LatestJobs />
        <WhyUs />
        <Statics />
        <Partners />
        <Footer />
    </>
  );
};

export default Home;
