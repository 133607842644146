import React from 'react'
import Nav from '../Nav'
import Header  from './HeaderPartners'
import Footer from '../Footer'
import Companies from './Companies'
import SocialBtns from '../SocialBtns'

function Partners() {
    return (
        <>
            <Nav />
            <Header />
            <SocialBtns />
            <Companies />
            <Footer />
        </>
    )
}

export default Partners
