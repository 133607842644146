import React from 'react';
import './Jobs.css'; 

import businessImage from '../../assets/business-ethics-01.png'
import mechanicImage from '../../assets/mechanic.png';
import accountingImage from '../../assets/accounting-book.png';
import salesImage from '../../assets/acquisition.png';
// import marketingImage from '../../assets/target.png';
import tourismImage from '../../assets/information-desk_1044259-01.png';
import medicalImage from '../../assets/medical_13428098-01.png';
import engineerImage from '../../assets/worker.png';
import learningImage from '../../assets/student_17010263-01.png';
import beautyImage from '../../assets/best-product_7959966-01.png'

const jobsData = [
  {
    title: 'الوظائف الإدارية والقانونية',
    imgSrc: businessImage,
    altText: 'الوظائف الإدارية والقانونية'
  },
  {
    title: 'المبيعات',
    imgSrc: salesImage,
    altText: 'المبيعات'
  },
  {
    title: 'المحاسبة والتدقيق',
    imgSrc: accountingImage,
    altText: 'المحاسبة والتدقيق'
  },
  {
    title: 'المهن الحرفية',
    imgSrc: mechanicImage,
    altText: 'المهن الحرفية'
  },
  {
    title: 'السياحة والضيافة',
    imgSrc: tourismImage,
    altText: 'السياحة والضيافة'
  },
  {
    title: 'الطب والرعاية الصحية',
    imgSrc: medicalImage,
    altText: 'الطب والرعاية الصحية'
  },
  {
    title: 'الهندسة',
    imgSrc: engineerImage,
    altText: 'الهندسة'
  },
  {
    title: 'التدريس والشؤون الاكاديمية',
    imgSrc: learningImage,
    altText: 'التدريس والشؤون الاكاديمية'
  },
  {
    title: 'الجمال والموضة',
    imgSrc: beautyImage,
    altText: 'الجمال والموضة'
  }
];

const Jobs = () => {
  return (
    <section className="jobs-section">
      <div className="container">
        {/* Section Header */}
        <h2 className="section-header">الوظائف حسب المجال</h2>
        <div className="title-line"></div>

        {/* Job Cards */}
        <div className="row">
          {jobsData.map((job, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
              <div className="card">
                <div className="card-img">
                  <img
                    src={job.imgSrc}
                    alt={job.altText}
                    className="img-fluid rounded-circle"
                  />
                </div>
                <h5 className="card-title">{job.title}</h5>
              </div>
            </div>
          ))}
        </div>

        {/* Second Row of Cards */}
        {/* <div className="row">
          {jobsData.map((job, index) => (
            <div className="col-lg-3 col-md-6" key={index + 4}>
              <div className="card">
                <div className="card-img">
                  <img
                    src={job.imgSrc}
                    alt={job.altText}
                    className="img-fluid rounded-circle"
                  />
                </div>
                <h5 className="card-title">{job.title}</h5>
              </div>
            </div>
          ))}
        </div> */}

        {/* <div className='read-more-btn'>
          <a className="read-more">اقرأ المزيد</a>
        </div> */}
      </div>
    </section>
  );
};

export default Jobs;