import React, { useState } from 'react';
import './ContactForm.css'; 

// import Lottie from 'lottie-react';


// import successAnimation from '../../assets/success.json';

import '../../firebase';
import { addDoc, collection, getFirestore } from 'firebase/firestore';


const ContactForm = () => {


  const [inputvalue1, setInputvalue1] = useState('');
  const [inputvalue2, setInputvalue2] = useState('');
  const [inputvalue3, setInputvalue3] = useState('');
  const [inputvalue4, setInputvalue4] = useState('');

  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState('success'); 


  const db = getFirestore();
  const setDataToFirestore = async (e) => {
      e.preventDefault();
      try {
          await addDoc(collection(db, "Contact_Form"), {
              name: inputvalue1,
              email: inputvalue2,
              phone: inputvalue3,
              message: inputvalue4,
          });
          setShowPopup(true);
          setInputvalue1('');
          setInputvalue2('');
          setInputvalue3('');
          setInputvalue4('');

          
          setPopupType('success');
        } catch (error) {
          console.error("Error: ", error);
          setPopupType('failure');
        }
        setShowPopup(true);
    
  };

  const handleClosePopup = () => {
      setShowPopup(false);
  };


  return (
    <>
    <div className="contact">
      <div className="content">
        <div className="left-side">
          <div className="address details">
            <i className="fas fa-map-marker-alt"></i>
            <div className="topic">العنوان</div>
            <div className="text-one">الجيزة،العجوزة</div>
            <div className="text-two">68 شارع أبو المحاسن الشاذلى خلف مسرح البالون</div>
          </div>
          <div className="phone details">
            <i className="fas fa-phone-alt"></i>
            <div className="topic">الهاتف</div>
            <div className="text-one">+002 01120220810</div>
            <div className="text-two">+00966504741970</div>
          </div>
          <div className="email details">
            <i className="fas fa-envelope"></i>
            <div className="topic">البريد الالكترونى</div>
            <div className="text-one">alahramco2020@gmail.com</div>
            <div className="text-two">cv.pyramids.egy@gmail.com</div>
          </div>
        </div>
        <div className="right-side">
          <div className="topic-text">تواصل معنا</div>
          <form dir="rtl"  
          method="POST" 
          className="contact-form" 
          onSubmit={setDataToFirestore}>

          {/* <form dir="rtl" action="https://api.web3forms.com/submit" method="POST"> */}
            {/* <input type="hidden" name="access_key" value="dcb7b193-2ea9-4c46-90c7-2883f5bd0f10" /> */}
            <div className="input-box">
              <input type="text" placeholder="اسمك" required  
              name="full_name"
              value={inputvalue1}
              onChange={(e) => setInputvalue1(e.target.value)}/>

            </div>
            <div className="input-box">
              <input type="text" placeholder="بريدك الالكترونى" required 
               name="email"
               value={inputvalue2}
               onChange={(e) => setInputvalue2(e.target.value)}/>
            </div>
            <div className="input-box">
              <input type="tel" placeholder="رقم الهاتف" dir='rtl' required 
               name="phone"
               value={inputvalue3}
               onChange={(e) => setInputvalue3(e.target.value)}/>
            </div>
            <div className="input-box message-box">
              <textarea placeholder="رسالتك" required
               name="message"
               value={inputvalue4}
               onChange={(e) => setInputvalue4(e.target.value)}></textarea>
            </div>
            <div className="button">
              <input type="submit" value="ارسل" />
            </div>
          </form>
        </div>

        {showPopup && (
        <div className="popup" onClick={handleClosePopup}>
          <div
            className={`popup-content ${popupType === 'success' ? 'popup-success' : 'popup-failure'}`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
          >
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <p>{popupType === 'success' ? 'تم ارسال طلبك بنجاح!' : 'فشل في إرسال الطلب !'}</p>
          </div>
        </div>
      )}
      </div>

      


    </div>
    <div className="map-iframe">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.185277667564!2d31.2126677749381!3d30.060223217823804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14584122003ddab9%3A0xfd8fe7af75d98e64!2zNjgg2KPYqNmIINin2YTZhdit2KfYs9mGINin2YTYtNin2LDZhNmK2Iwg2KfZhNit2YjZitiq2YrYqdiMINit2Yog2KfZhNi52KzZiNiy2KnYjCDZhdit2KfZgdi42Kkg2KfZhNis2YrYstipIDM3NTM2MTI!5e0!3m2!1sar!2seg!4v1715807861549!5m2!1sar!2seg"
      width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Map"
    ></iframe>
  </div>
  </>
  );
};

export default ContactForm;