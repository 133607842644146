import React from 'react';
import './WhyUs.css'; 
import { IoChevronBackOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

import whyusImage from '../../assets/whyUsImg.png';

const WhyUs = () => {
  return (

    <div className='container'>
    <section className='message'>
        <div className='right-message'>
            <div className='our-message'>
                <h2>لماذا نحن</h2>
                <div className='title__line'></div>
                <p>
                 الالتزام بالامانة والصدق مع مساهمينا من أصحاب الشركات لتقديم خدمة الموارد البشرية لصاحب العمل والموظف وان نكون شركة توظيف صداقة ذات مصدقية موثوقة كشركة عالمية رائدة في وكالة توظيف قوى عاملة في مصر
                </p>
            </div>

            <Link to='/about' className='read__more'>
            اقرأ المزيد
               <IoChevronBackOutline />
             </Link>
        </div>
        <div className='left-message'>
            <img src={whyusImage} />
        </div>
    </section>
</div>
    // <div className="container">
    //   <div className="why-us" dir='rtl'>
    //     {/* Right Section */}
    //     {/* <div className="right">
    //       <h2 className='h-us'>لماذا نحن</h2>
    //       <div className="line"></div>
    //       <div className="content">
    //         <p className='whyUs-p'>
    //           شركة الاهرام لإلحاق العمالة المصرية بالخارج ترخيص رقم 128 إحدى شركات التوظيف الرائدة في جمهورية مصر العربية
    //           شركة الاهرام لإلحاق العمالة المصرية بالخارج ترخيص رقم 128 إحدى شركات التوظيف الرائدة في جمهورية مصر
    //           العربية شركة الاهرام لإلحاق العمالة المصرية بالخارج ترخيص رقم 128 إحدى شركات التوظيف الرائدة في
    //           جمهورية مصر العربية شركة الاهرام لإلحاق العمالة المصرية بالخارج ترخيص رقم 128 إحدى شركات التوظيف الرائدة
    //           في جمهورية مصر العربية شركة الاهرام لإلحاق العمالة المصرية بالخارج ترخيص رقم 128 إحدى شركات التوظيف
    //           الرائدة في جمهورية مصر العربية
    //         </p>
    //         <Link to='/about' className='read__more'>
    //           اقرأ المزيد
    //           <IoChevronBackOutline />
    //         </Link>
    //       </div>
    //     </div> */}

    //     {/* Left Section */}
    //     {/* <div className="left">
    //       <img
    //         src={whyusImage}
            
    //       />
    //     </div> */}
    //   </div>
    // </div>
  );
};

export default WhyUs;