import React from 'react'
import './OurMessage.css'
import img1 from '../../assets/about 2-01.png'

function OurMessage() {
    return (
        <div className='container'>
            <section className='message'>
                <div className='right-message'>
                    <div className='our-message'>
                        <h2>رسالتنا</h2>
                        <div className='title__line'></div>
                        <p>
                        عرض خدمات في مجال الموارد البشرية عالية الكفاءة في منطقة الخليج العربي ومصر. تعيين مرشحينا في المكان المناسب لتنمية قدرتهم ولتحقيق حياة عملية ناجحة. تحسين إجراءات الترشيح من خلال اختبارات خاصة بالموارد البشرية لضمان نجاح مؤسستكم. دعم مرشحينا بالتدريب المستمر لضمان كفاءتهم. مساعدة المصريين بتوفير وظائف ملائمة لهم خارج مصر لكي نمنحهم الفرصة لاكتساب الخبرات العملية في سوق العمل الدولي.                        </p>
                    </div>
                    <div className='our-vission'>
                        <h2>رؤيتنا</h2>
                        <div className='title__line'></div>
                        <p>
                        نحن ملتزمون بدعم مبادئ النزاهة والأخلاق والمبادئ والقيم والصدق مع مساهمينا وأصحاب المصلحة. نسعى لتقديم خدمة الموارد البشرية التي تعود بالفائدة على كل من صاحب العمل والموظف ؛ وأن تكون شركة توظيف موثوقة وصادقة وملتزمة. نحن نعتبر أنفسنا وكالة عالمية رائدة لتوظيف القوى العاملة من مصر.                        </p>
                    </div>
                </div>
                <div className='left-message'>
                    <img src={img1} />
                </div>
            </section>
        </div>
    )
}

export default OurMessage
