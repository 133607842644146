import React from 'react'
import './HeaderAbout.css'

function HeaderAbout() {
    return (
        <section className="hero4_container">
        <div className="container">
            <h1>
                عن الشركة
            </h1>
            <div className='line'>
                
            </div>
        </div>
    </section>
    )
}

export default HeaderAbout
