import React, { useState } from 'react';
import Nav from '../Nav';
import HeaderJob from './HeaderJob';
import Search from './Search';
import AvailableJobs, { jobsNumber } from './AvailableJobs';
import Footer from '../Footer';

const Jobs = ({jobsData}) => {
    const [searchTerm, setSearchTerm] = useState('');
    

    return (
        <div>
            <Nav />
            <HeaderJob />
            <Search onSearch={setSearchTerm} jobsNumber={jobsNumber}/> 
            <AvailableJobs searchTerm={searchTerm} /> 
            <Footer />
        </div>
    );
};

export default Jobs;