import React from 'react'
import Nav from '../Nav'
import HeaderApplier from './HeaderApplier'
import SocialBtns from '../SocialBtns'
import ApplierForm from './ApplierForm'
import Footer from '../Footer'

function Applier() {
    return (
        <>
            <Nav />
            <HeaderApplier />
            <SocialBtns />
            <ApplierForm />
            <Footer />
        </>
    )
}

export default Applier
