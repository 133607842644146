import React from 'react';
import './HeaderJob.css';

function HeaderJob() {
    return (
        <section className="hero1_container">
            <div className="container">
                <h1>
                    الوظائف
                </h1>
                <div className='line'>
                    
                </div>
            </div>
        </section>
    )
}

export default HeaderJob
