import React from 'react'
import './HeaderDetails.css'

function HeaderDetails() {
    return (
        <section className="hero7_container">
       
    </section>
    )
}

export default HeaderDetails
