// import React, { useState } from "react";
// import './Nav.css'; 
// import logoImg from '../assets/logo.png';
// import { Link } from 'react-router-dom';

// const Nav = () => {
//   const [activeLink, setActiveLink] = useState("/");

//   const handleLinkClick = (link) => {
//     setActiveLink(link);
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-custom">
//       <div className="container">
//         {/* Burger Icon */}
//         <button
//           className="navbar-toggler custom-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarNav"
//           aria-controls="navbarNav"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>


//        {/* Logo */}
//        <a className="navbar-brand ms-auto order-lg-2" href="#">
//             <img src={logoImg} alt="Logo" className="nav-logo" />
//         </a>  
          

//         {/* Nav Links */}
//         <div className="collapse navbar-collapse" id="navbarNav" dir="rtl">
//       <ul className="navbar-nav mx-auto">
//         <li className="nav-item">
//           <Link
//             to="/"
//             className={`nav-link ${activeLink === "/" ? "active" : ""}`}
//             onClick={() => handleLinkClick("/")}
//           >
//             الرئيسية
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link
//             to="/About"
//             className={`nav-link ${activeLink === "/About" ? "active" : ""}`}
//             onClick={() => handleLinkClick("/About")}
//           >
//             من نحن
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link
//             to="/Jobs"
//             className={`nav-link ${activeLink === "/Jobs" ? "active" : ""}`}
//             onClick={() => handleLinkClick("/Jobs")}
//           >
//             الوظائف
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link
//             to="/Partners"
//             className={`nav-link ${activeLink === "/Partners" ? "active" : ""}`}
//             onClick={() => handleLinkClick("/Partners")}
//           >
//             الشركاء
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link
//             to="/Contact"
//             className={`nav-link ${activeLink === "/Contact" ? "active" : ""}`}
//             onClick={() => handleLinkClick("/Contact")}
//           >
//             تواصل معنا
//           </Link>
//         </li>
//       </ul>

//       {/* Login/Signup Links */}
//       <ul className="login-container">
//         <li className="nav-item">
//           <a className="" href="#">
//             {/* <a className="nav-link signup" href="#"> */}
//             {/* انشاء حساب */}
//           </a>
//         </li>
//         <li className="nav-item">
//           <a className="" href="#">
//             {/* <a className="nav-link login" href="#"> */}
//             {/* تسجيل دخول */}
//           </a>
//         </li>
//       </ul>
//     </div>


    
//       </div>
//     </nav>
//   );
// };

// export default Nav;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Ensure you're using `react-router-dom`
import logoImg from '../assets/logo.png';
import './Nav.css'; 

const Nav = () => {
  const [activeLink, setActiveLink] = useState('/');
  const location = useLocation();

  useEffect(() => {
    // Update the active link based on the current URL
    setActiveLink(location.pathname);
  }, [location]);

  const handleLinkClick = (path) => {
    setActiveLink(path); // Update the active link when clicked
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-custom">
      <div className="container">
        {/* Burger Icon */}
        <button
          className="navbar-toggler custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Logo */}
        <div className="navbar-brand ms-auto order-lg-2">
          <img src={logoImg} alt="Logo" className="nav-logo" />
        </div>

        {/* Nav Links */}
        <div className="collapse navbar-collapse" id="navbarNav" dir="rtl">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link
                to="/"
                className={`nav-link ${activeLink === "/" ? "active" : ""}`}
                onClick={() => handleLinkClick("/")}
              >
                الرئيسية
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about"
                className={`nav-link ${activeLink === "/about" ? "active" : ""}`}
                onClick={() => handleLinkClick("/about")}
              >
                من نحن
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/jobs"
                className={`nav-link ${activeLink === "/jobs" ? "active" : ""}`}
                onClick={() => handleLinkClick("/jobs")}
              >
                الوظائف
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/partners"
                className={`nav-link ${activeLink === "/partners" ? "active" : ""}`}
                onClick={() => handleLinkClick("/partners")}
              >
                الشركاء
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className={`nav-link ${activeLink === "/contact" ? "active" : ""}`}
                onClick={() => handleLinkClick("/contact")}
              >
                تواصل معنا
              </Link>
            </li>
          </ul>

          {/* Login/Signup Links */}
          <ul className="login-container">
            <li className="nav-item">
              <a className="" href="#">
                {/* Add Login/Signup functionality here */}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
