import React from 'react'
import './OwnerServices.css'
import img1 from '../../assets/handsome-businessman-signing-contract-with-partner.jpg'

function OwnerServices() {
    return (
        <div className='container'>
        <section className='ownerServices'>
            <div className='right-ownerServices'>
                <div className='services'>
                    <h2>الخدمات التي نقدمها لأصحاب العمل</h2>
                    <div className='title__line'></div>
                    <p>
                    نحن ملتزمون بكل الوعود والتعهدات التي قطعناها على أنفسنا من أجل عملائنا الكرام ونعمل على جلب أفضل الموارد البشرية إلى أعلى معايير التميز.                    </p>
                </div>
            </div>
            <div className='left-ownerServices'>
                <img src={img1} />
            </div>
        </section>
    </div>
    )
}

export default OwnerServices
