import { initializeApp } from "firebase/app";
import firebase from "firebase/app";
// import "firebase/firestore";
import { getFirestore } from 'firebase/firestore';


// import { getAuth } from "firebase/auth";
// import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyA4Hkqyn6QXnEiDGSWm9VCterK_yOa6FDw",
    authDomain: "elahram-react.firebaseapp.com",
    databaseURL: "https://elahram-react-default-rtdb.firebaseio.com",
    projectId: "elahram-react",
    storageBucket: "elahram-react.appspot.com",
    messagingSenderId: "1017984176114",
    appId: "1:1017984176114:web:368c75916ec165828f256b"
// apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
// authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
// projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
// storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
// messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
// appId: process.env.REACT_APP_FIREBASE_APP_ID,
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const firebaseApp = firebase.initializeApp(firebaseConfig);

// const db = firebaseApp.firestore();

const db = getFirestore(app);

// export const auth = getAuth();
// export const storage = getStorage(app);


export { db ,app };