import React, { useState } from 'react';
import './Companies.css'; 

// icons
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";


import img1 from '../../assets/companies/قطاع المقاولات/اركان.png'
import img2 from '../../assets/companies/قطاع المقاولات/اطوال الجزيرة.png'
import img3 from '../../assets/companies/قطاع المقاولات/الأومير.jpg'
import img4 from '../../assets/companies/قطاع المقاولات/الاداء المتوازن.png'
import img5 from '../../assets/companies/قطاع المقاولات/الجبال الشمالية.jpg'
import img6 from '../../assets/companies/قطاع المقاولات/الجناحين.png'
import img7 from '../../assets/companies/قطاع المقاولات/الحيدر.png'
import img8 from '../../assets/companies/قطاع المقاولات/الدهامى.png'
import img9 from '../../assets/companies/قطاع المقاولات/الديار القطرية.jpg'
import img10 from '../../assets/companies/قطاع المقاولات/هوزان الجزيرة.jpg'
import img11 from '../../assets/companies/قطاع المقاولات/مشاريع الجزيرة.jpg'
import img12 from '../../assets/companies/قطاع المقاولات/آل طاوى.png'
import img13 from '../../assets/companies/قطاع المقاولات/آل مترى.png'
import img14 from '../../assets/companies/قطاع المقاولات/ابناء صالح الجهنى.png'
import img15 from '../../assets/companies/قطاع المقاولات/مستورة.jpg'
import img16 from '../../assets/companies/قطاع المقاولات/محولات الحجاز.jpg'
import img17 from '../../assets/companies/قطاع المقاولات/مبانى الرياض.png'
import img18 from '../../assets/companies/قطاع المقاولات/قمة الرواسى.png'
import img19 from '../../assets/companies/قطاع المقاولات/فجر.jpg'
import img20 from '../../assets/companies/قطاع المقاولات/على شار.jpg'
import img21 from '../../assets/companies/قطاع المقاولات/عبدالرحمن سعد الراشد.png'
import img22 from '../../assets/companies/قطاع المقاولات/شبه الجزيرة.jpg'
import img23 from '../../assets/companies/قطاع المقاولات/سنابل المشعان.png'
import img24 from '../../assets/companies/قطاع المقاولات/سبك.png'
import img25 from '../../assets/companies/قطاع المقاولات/سبع سنابل.png'
import img26 from '../../assets/companies/قطاع المقاولات/الراجحى.png'
import img27 from '../../assets/companies/قطاع المقاولات/الراشد.png'
import img28 from '../../assets/companies/قطاع المقاولات/سبأ.png'
import img29 from '../../assets/companies/قطاع المقاولات/السرحان.png'
import img30 from '../../assets/companies/قطاع المقاولات/الشرهان.png'
import img31 from '../../assets/companies/قطاع المقاولات/العطيشان.jpg'
import img32 from '../../assets/companies/قطاع المقاولات/الفهد.png'
import img33 from '../../assets/companies/قطاع المقاولات/القحطانى القابضة.png'
import img34 from '../../assets/companies/قطاع المقاولات/القديرى.png'
import img35 from '../../assets/companies/قطاع المقاولات/الكفاح.png'
import img36 from '../../assets/companies/قطاع المقاولات/اللين و الاعمار.png'
import img37 from '../../assets/companies/قطاع المقاولات/راك الجزيرة.png'
import img38 from '../../assets/companies/قطاع المقاولات/دسر.png'
import img39 from '../../assets/companies/قطاع المقاولات/بن جار الله.png'
import img40 from '../../assets/companies/قطاع المقاولات/امباط.png'
import img41 from '../../assets/companies/قطاع المقاولات/اليمامة.png'
import img42 from '../../assets/companies/قطاع المقاولات/الوادى الاخضر.png'
import img43 from '../../assets/companies/قطاع المقاولات/النوير الذهبية.png'
import img44 from '../../assets/companies/قطاع المقاولات/المطيرى.jpg'
import img45 from '../../assets/companies/قطاع المقاولات/المحب.jpg'
import img46 from '../../assets/companies/قطاع المقاولات/المتمكن.jpg'

import img47 from '../../assets/companies/الخرسانة/noortec.png'
import img48 from '../../assets/companies/الخرسانة/اسمنت الجوف.png'
import img49 from '../../assets/companies/الخرسانة/اسمنت العربية.jpg'
import img51 from '../../assets/companies/الخرسانة/اسمنت القصيم.png'
import img52 from '../../assets/companies/الخرسانة/اسمنت المنطقة الشمالية.png'
import img53 from '../../assets/companies/الخرسانة/اسمنت اليمامة.png'
import img54 from '../../assets/companies/الخرسانة/اسمنت ام القرى.png'
import img55 from '../../assets/companies/الخرسانة/اسمنت تبوك.png'
import img56 from '../../assets/companies/الخرسانة/اسمنت حائل.png'
import img57 from '../../assets/companies/الخرسانة/اسمنت نجرات.jpg'
import img58 from '../../assets/companies/الخرسانة/اسمنت ينبع.jpg'
import img59 from '../../assets/companies/الخرسانة/السريع التجارية الصناعية.png'
import img60 from '../../assets/companies/الخرسانة/زهرة الواحة للتجارة.png'
import img61 from '../../assets/companies/الخرسانة/حديد الراجحى.png'
import img62 from '../../assets/companies/الخرسانة/الناصر.png'
import img63 from '../../assets/companies/الخرسانة/زهرة الواحة للتجارة.png'

import img64 from '../../assets/companies/الصناعات الغذائية/arabica coffee.png'
import img65 from '../../assets/companies/الصناعات الغذائية/el-najdain.webp'
import img66 from '../../assets/companies/الصناعات الغذائية/nervana.png'
import img67 from '../../assets/companies/الصناعات الغذائية/vein.jpg'
import img68 from '../../assets/companies/الصناعات الغذائية/vivi.png'
import img69 from '../../assets/companies/الصناعات الغذائية/ابار للصناعات الغذائية.jpeg'
import img70 from '../../assets/companies/الصناعات الغذائية/ابو كاس.jpg'
import img71 from '../../assets/companies/الصناعات الغذائية/اكوا دلتا.png'
import img72 from '../../assets/companies/الصناعات الغذائية/الراشد للاغذية.jpg'
import img73 from '../../assets/companies/الصناعات الغذائية/المنجم للصناعات الغذائية.png'
import img74 from '../../assets/companies/الصناعات الغذائية/المنهل.png'
import img75 from '../../assets/companies/الصناعات الغذائية/الهنا.png'
import img76 from '../../assets/companies/الصناعات الغذائية/نوفا.png'
import img77 from '../../assets/companies/الصناعات الغذائية/مياه رفا.png'
import img78 from '../../assets/companies/الصناعات الغذائية/مياه رست -صناعات غذائية.png'
import img79 from '../../assets/companies/الصناعات الغذائية/مياه الوادي.jpeg'
import img80 from '../../assets/companies/الصناعات الغذائية/مياه القصيم.png'
import img81 from '../../assets/companies/الصناعات الغذائية/موارد.png'
import img82 from '../../assets/companies/الصناعات الغذائية/مروج.jpeg'
import img83 from '../../assets/companies/الصناعات الغذائية/لونا.png'
import img84 from '../../assets/companies/الصناعات الغذائية/قصر لذائذ.jpeg'
import img85 from '../../assets/companies/الصناعات الغذائية/غندور.png'
import img86 from '../../assets/companies/الصناعات الغذائية/شركة المياة الوطنية.png'
import img87 from '../../assets/companies/الصناعات الغذائية/شاي ربيع.jpg'
import img88 from '../../assets/companies/الصناعات الغذائية/اورجنال للاغذية-عصير.jpeg'
import img89 from '../../assets/companies/الصناعات الغذائية/بابطين.png'
import img90 from '../../assets/companies/الصناعات الغذائية/باطينكو.jpeg'
import img91 from '../../assets/companies/الصناعات الغذائية/حلوة.jpg'
import img92 from '../../assets/companies/الصناعات الغذائية/حلويات سعد الدين1.jpeg'
import img93 from '../../assets/companies/الصناعات الغذائية/حلويات سعدالدين2.png'
import img94 from '../../assets/companies/الصناعات الغذائية/شاهين.png'
import img95 from '../../assets/companies/الصناعات الغذائية/شافولا.png'
import img96 from '../../assets/companies/الصناعات الغذائية/سنابل الشهد-صناعات غذائية.jpeg'
import img97 from '../../assets/companies/الصناعات الغذائية/ديما.png'

import img98 from '../../assets/companies/القطاع الصحي/PMC.png'
import img99 from '../../assets/companies/القطاع الصحي/البشرى الطبية.png'
import img100 from '../../assets/companies/القطاع الصحي/الربوة الطبى.png'
import img101 from '../../assets/companies/القطاع الصحي/الرعاية الصحية الاولية.png'
import img102 from '../../assets/companies/القطاع الصحي/الفيحاء الطبية.png'
import img103 from '../../assets/companies/القطاع الصحي/عيادات البدرى.png'
import img104 from '../../assets/companies/القطاع الصحي/عيادات الموعد الطبى.png'
import img105 from '../../assets/companies/القطاع الصحي/كنوز الصحة.png'
import img106 from '../../assets/companies/القطاع الصحي/مجمع العليا.png'
import img107 from '../../assets/companies/القطاع الصحي/مركز الشبلان.png'
import img108 from '../../assets/companies/القطاع الصحي/مركز كيان.png'
import img109 from '../../assets/companies/القطاع الصحي/مركز مارينا الطبى.png'
import img110 from '../../assets/companies/القطاع الصحي/مستشفى النخبة.png'

import img111 from '../../assets/companies/مكاتب الاستقدام/raha.png'
import img112 from '../../assets/companies/مكاتب الاستقدام/اجير.png'
import img113 from '../../assets/companies/مكاتب الاستقدام/اركو.png'
import img114 from '../../assets/companies/مكاتب الاستقدام/الخيار الاول.jpg'
import img115 from '../../assets/companies/مكاتب الاستقدام/الصويدر.png'
import img116 from '../../assets/companies/مكاتب الاستقدام/امانة المستقدم.png'
import img117 from '../../assets/companies/مكاتب الاستقدام/ايوان.png'
import img118 from '../../assets/companies/مكاتب الاستقدام/ترف.jpg'
import img119 from '../../assets/companies/مكاتب الاستقدام/جسور الامداد.png'
import img120 from '../../assets/companies/مكاتب الاستقدام/حطين.png'
import img121 from '../../assets/companies/مكاتب الاستقدام/درة الرياض.png'
import img122 from '../../assets/companies/مكاتب الاستقدام/راحة للاستقدام.png'
import img123 from '../../assets/companies/مكاتب الاستقدام/وسيط المدينة.png'
import img124 from '../../assets/companies/مكاتب الاستقدام/ناس.png'
import img125 from '../../assets/companies/مكاتب الاستقدام/مهارات الايادى.png'
import img126 from '../../assets/companies/مكاتب الاستقدام/فن الاختيار.png'
import img127 from '../../assets/companies/مكاتب الاستقدام/سند.jpg'
import img128 from '../../assets/companies/مكاتب الاستقدام/سماسكو.png'
import img129 from '../../assets/companies/مكاتب الاستقدام/ركن التعاون.png'
import img130 from '../../assets/companies/مكاتب الاستقدام/ركن اديم.png'

import img131 from '../../assets/companies/التربية و التعليم/اسرارى.jpg'
import img132 from '../../assets/companies/التربية و التعليم/البيارق الاهلية.jpg'
import img133 from '../../assets/companies/التربية و التعليم/التعليم المتطور.png'
import img134 from '../../assets/companies/التربية و التعليم/الحبيل الخاصة.jpg'
import img135 from '../../assets/companies/التربية و التعليم/الحويلات الابتدائية.jpg'
import img136 from '../../assets/companies/التربية و التعليم/الحياة.png'
import img137 from '../../assets/companies/التربية و التعليم/العقيق.jpg'
import img138 from '../../assets/companies/التربية و التعليم/العلم النافع الاهلية.jpg'
import img139 from '../../assets/companies/التربية و التعليم/القلعة الحجازية.jpg'
import img140 from '../../assets/companies/التربية و التعليم/النخبة العلمية.jpg'
import img141 from '../../assets/companies/التربية و التعليم/تربية الابناء الاهلية.png'
import img142 from '../../assets/companies/التربية و التعليم/جواثا الاهلية.png'
import img143 from '../../assets/companies/التربية و التعليم/منارات القصيم.jpg'
import img144 from '../../assets/companies/التربية و التعليم/مدارس الفردوس الاهلية.jpg'
import img145 from '../../assets/companies/التربية و التعليم/مدارس العليا الاهلية.jpg'
import img146 from '../../assets/companies/التربية و التعليم/ماريا العالمية.png'
import img147 from '../../assets/companies/التربية و التعليم/روضة عهد الوفاء.jpg'

import img148 from '../../assets/companies/المطاعم و الكافيهات/soltana.ma.png'
import img149 from '../../assets/companies/المطاعم و الكافيهات/اتحاد ميار.jpg'
import img150 from '../../assets/companies/المطاعم و الكافيهات/ارابيكا فود.png'
import img151 from '../../assets/companies/المطاعم و الكافيهات/ارتال.jpg'
import img152 from '../../assets/companies/المطاعم و الكافيهات/ارتواء.jpg'
import img153 from '../../assets/companies/المطاعم و الكافيهات/البيت السعودى.png'
import img154 from '../../assets/companies/المطاعم و الكافيهات/البيك.png'
import img155 from '../../assets/companies/المطاعم و الكافيهات/الريف.png'
import img156 from '../../assets/companies/المطاعم و الكافيهات/القلزم.png'
import img157 from '../../assets/companies/المطاعم و الكافيهات/بارن كافيه.jpg'
import img158 from '../../assets/companies/المطاعم و الكافيهات/بيت الشواية.png'
import img159 from '../../assets/companies/المطاعم و الكافيهات/بيت الفلافل.png'
import img160 from '../../assets/companies/المطاعم و الكافيهات/كيان.jpg'
import img161 from '../../assets/companies/المطاعم و الكافيهات/قصر الشرق.jpg'
import img162 from '../../assets/companies/المطاعم و الكافيهات/شواية الخليج.png'
import img163 from '../../assets/companies/المطاعم و الكافيهات/شاورما اند مور.jpg'
import img164 from '../../assets/companies/المطاعم و الكافيهات/سينابون.jpg'
import img165 from '../../assets/companies/المطاعم و الكافيهات/سلطانة.jpg'
import img166 from '../../assets/companies/المطاعم و الكافيهات/ست الشام.jpg'
import img167 from '../../assets/companies/المطاعم و الكافيهات/ريشيو.png'
import img168 from '../../assets/companies/المطاعم و الكافيهات/ريدان.jpg'
import img169 from '../../assets/companies/المطاعم و الكافيهات/دانة الارياف.png'
import img170 from '../../assets/companies/المطاعم و الكافيهات/دار حواء.png'

const Companies = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const partnersData = [
    {
      title: 'قطاع المقاولات بالسعودية',
      images: [
        img1, img2, img3, img4 ,img5 , img6 ,img7,img8,img9,img10,img11,img12,img13,img14,img15,
        img16, img17, img18, img19 ,img20 , img21 ,img22,img23,img24,img25,img26,img27,img28,img29,img30,
        img31, img32, img33, img34 ,img35 , img36 ,img37,img38,img39,img40,img41,img42,img43,img44,img45,img46
      ],
    },
    {
      title: 'قطاع الخرسانة الجاهزة و البلوك',
      images: [
        img47,img48,img49,img51,img52,img53,img54,
        img55,img56,img57,img58,img59,img60,img61,img62,img63
      ],
    },
    {
      title: 'قطاع الصناعات الغذائية',
      images: [
                img64,img65,img66,img67,img68,img69,img70,img71,img72,img73,
                img74,img75,img76,img77,img78,img79,img80,img81,img82,img83,
                img84,img85,img86,img87,img88,img89,img90,img91,img92,img93,img94,img95,img96,img97
      ],
    },
    {
        title: 'القطاع الصحى',
        images: [
            img98,img99,img100,img101,img102,img103,img103,img104,img105,img106,
            img107,img108,img109,img110 
        ],
      },
      {
        title: 'قطاع مكاتب الاستقدام',
        images: [
                  img111,img112,img113,img114,img115,img116,img117,img118,img119,img120,
                  img121,img122,img123,img124,img125,img126,img127,img128,img129,img130
        ],
      },
      {
        title: 'قطاع التربية و التعليم',
        images: [
                  img131,img132,img133,img134,img135,img136,img137,img138,img139,img140,
                  img141,img142,img143,img144,img145,img146,img147
        ],
      },
      {
        title: 'قطاع المطاعم و الكافيهات',
        images: [
                  img148,img149,img150,img151,img152,img153,img154,img155,img156,img157,
                  img158,img159,img160,img161,img162,img163,img164,img165,img166,img167,
                  img168,img169,img170
        ],
      },
  ];

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : partnersData.length - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev < partnersData.length - 1 ? prev + 1 : 0));
  };

  return (
    <div dir="rtl" className="partners-container">
      <div className="hero">
        <div className="com-container">
          <h1>{partnersData[currentPage].title}</h1>
          <div className="row">
            {partnersData[currentPage].images.map((imgSrc, index) => (
              <div key={index}>
                <img src={imgSrc} alt={`Partner ${index}`} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="pagination">
        <button onClick={handlePrevPage}>
        <MdOutlineArrowForwardIos />

        </button>
        <span>
          صفحة {currentPage + 1} من {partnersData.length}
        </span>
        <button onClick={handleNextPage}>
<MdOutlineArrowBackIos />

        </button>
      </div>
    </div>
  );
};

export default Companies;
