import React from 'react'
import Nav from '../Nav'
import HeaderAbout from './HeaderAbout'
import Footer from '../Footer'
import SocialBtns from '../SocialBtns'
import DownloadProfile from './DownloadProfile'
import OurMessage from './OurMessage'
import AboutUs from './AboutUs'
import OwnerServices from './OwnerServices'
import ApplierServices from './ApplierServices'

function About() {
    return (
        <>
            <Nav />
            <HeaderAbout />
            <SocialBtns />
            <DownloadProfile />
            <OurMessage />
            <AboutUs />
            <OwnerServices />
            <ApplierServices />
            <Footer />
        </>
    )
}

export default About
