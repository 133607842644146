import React, { useState } from 'react';
import './AvailableJobs.css';
import { IoChevronBackOutline } from "react-icons/io5";

import architectImg from '../../assets/building-construction-worker-site.jpg';
import teacherImg from '../../assets/portrait-professor-work-educational-system.jpg';
import nurseImg from '../../assets/portrait-nurse-holding-clipboard.jpg';
import accountImg from '../../assets/checking-workplace-table-counting-man.jpg'

const jobsData = [
    { title: 'مهندس معماري', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مدرسين', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: teacherImg },
    { title: 'ممرضات', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: nurseImg },
    { title: 'محاسب', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: accountImg },
    { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مهندس معماري', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مدرسين', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: teacherImg },
    { title: 'ممرضات', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: nurseImg },
    { title: 'محاسب', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: accountImg },
    { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
    { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg }
];

// console.log("available :", jobsData)

const itemsPerPage = 12;

export const jobsNumber = jobsData.length;
// console.log("jobsNum:" , jobsNumber)

const AvailableJobs = ({ searchTerm }) => {
    const [currentPage, setCurrentPage] = useState(1);

    // Filter the jobs based on the search term
    const filteredJobs = jobsData.filter(job =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredJobs.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const renderJobs = () => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        return filteredJobs.slice(start, end).map((job, index) => (
            <div className="col-lg-5" key={index}>
                <div className="job-card">
                    <img src={job.img} className="img-fluid" alt={job.title} />
                    <div className="job-details">
                        <h5 className="job-title">{job.title}</h5>
                        <p>{job.desc1}</p>
                        <a href="#" className="read-details">
                            اقرأ التفاصيل
                            <IoChevronBackOutline />
                        </a>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <section className='available-jobs'>
            <div className='container'>
                <div className="row" id="jobCardsContainer">
                    {renderJobs()}
                </div>

                <div className="pagination-container">
                    <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={handlePrevPage}> &lt; </button>
                        </li>

                        {[...Array(Math.ceil(filteredJobs.length / itemsPerPage)).keys()].map(page => (
                            <li key={page + 1} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageClick(page + 1)}>{page + 1}</button>
                            </li>

                        ))}
                        <li className={`page-item ${currentPage === Math.ceil(filteredJobs.length / itemsPerPage) ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={handleNextPage}> &gt; </button>
                        </li>

                    </ul>
                </div>
            </div>
        </section>
    );
};

export default AvailableJobs;