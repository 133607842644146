import React from 'react'
import './Details.css'
import { IoChevronBackOutline } from "react-icons/io5";

function Details() {
    return (
        <section className='job-details' dir='rtl'>
            <div className='container'>
                <div className='job-cont'>
                    <h2>وصف الوظيفة</h2>
                    <div className="line"></div>
                    <p>مطلوب محاسب بنوك-محاسب موارد بشرية للسعودية</p>
                </div>
                <div className='job-cont'>
                    <h2>متطلبات الوظيفة</h2>
                    <div className="line"></div>
                    <p>مطلوب محاسب بنوك-محاسب موارد بشرية للسعودية</p>
                </div>
                <div className='job-cont'>    
                    <h2>مميزات الوظيفة</h2>
                    <div className="line"></div>
                    <p>مطلوب محاسب بنوك-محاسب موارد بشرية للسعودية</p>
                </div>
                <div className='job-cont'>    
                    <h2>معلومات الوظيفة</h2>
                    <div className="line"></div>
                    <p>مطلوب محاسب بنوك-محاسب موارد بشرية للسعودية</p>
                </div>  

                <button className='applyForJob'>
                    سجل فى الوظيفة
                    <IoChevronBackOutline />
                </button>


            </div>
        </section>
    )
}

export default Details
