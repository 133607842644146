import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Page';
import Jobs from './components/Jobs/Jobs';
import Partners from './components/Parteners/Partners';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Owner from './components/Owner/Owner';
import Applier from './components/Applier/Applier';
import JobDetails from './components/JobDetails/JobDetails';

const App = () => {
  return (
    <Router> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/owner" element={<Owner />} />
          <Route path="/applier" element={<Applier />} />
          <Route path="/details" element={<JobDetails />} />
        </Routes>
    </Router>
  );
};

export default App;
