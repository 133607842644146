import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ApplierForm.css';
import { IoChevronBackOutline } from "react-icons/io5";

import '../../firebase';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
// import { getDownloadURL, getStorage, ref, uploadBytesResumable,uploadBytes } from 'firebase/storage';

// import {  firestore } from '../../firebase'; 

// import { v4 } from 'uuid';
// import { ref, , getDownloadURL } from "firebase/storage";

const ApplierForm = () => {


  const [nameValue, setNameValue] = useState(""); // Full name
  const [phoneValue, setPhoneValue] = useState(""); // Phone number
  const [birthDate, setBirthDate] = useState(""); // Birth date
  const [birthPlace, setBirthPlace] = useState(""); // Birth place
  const [currentProfession, setCurrentProfession] = useState(""); // Current profession
  const [residence, setResidence] = useState(""); // Residence
  const [nationalId, setNationalId] = useState(""); // National ID
  const [passportNumber, setPassportNumber] = useState(""); // Passport number
  const [maritalStatus, setMaritalStatus] = useState(""); // Marital status
  const [religion, setReligion] = useState(""); // Religion
  const [education, setEducation] = useState(""); // Education
  const [specialization, setSpecialization] = useState(""); // Specialization
  const [graduationInstitution, setGraduationInstitution] = useState(""); // Graduation institution
  const [graduationYear, setGraduationYear] = useState(""); // Graduation year
  const [licenseValue, setLicenseValue] = useState(""); // License
  const [licenseTypeValue, setLicenseTypeValue] = useState(""); // License type

  const [errorMessage, setErrorMessage] = useState("");

  const [fileValue, setFile] = useState(null); // File input

  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState('success'); // Can be 'success' or 'failure'

  const handleLicenseTypeChange = (e) => {
    setLicenseTypeValue(e.target.value);
  };

  // const [showPopup, setShowPopup] = useState(false);

  const db = getFirestore();

  // const handleFileUpload = (file) => {
  //   if (!file) return;

  //   const storage = getStorage(); // Initialize storage
  //   const storageRef = ref(storage, `uploads/${file.name}`); // Create a reference to the file

  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       // Progress function ...
  //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       console.log("Upload is " + progress + "% done");
  //     },
  //     (error) => {
  //       // Handle unsuccessful uploads
  //       console.error("File upload error:", error);
  //     },
  //     () => {
  //       // Handle successful uploads on complete
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         console.log("File available at", downloadURL);
  //         // You can save the download URL to Firestore or use it however you need
  //       });
  //     }
  //   );
  // };


//   const handleRetry = async (fn, retries = 3, delay = 1000) => {
//     for (let i = 0; i < retries; i++) {
//         try {
//             return await fn();
//         } catch (error) {
//             console.error(`Attempt ${i + 1} failed: ${error.message}`);
//             if (i < retries - 1) {
//                 await new Promise(res => setTimeout(res, delay)); // Wait before retrying
//             } else {
//                 throw error; // Rethrow if out of retries
//             }
//         }
//     }
// };


//   const handleUpload = async (e, setImageCallback, setIsUploadingCallback) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     setIsUploadingCallback(true);
//     const imgRef = ref(storage, `imgs/${v4()}`);

//     try {
//         const uploadTask = async () => {
//             const snapshot = await uploadBytes(imgRef, file);
//             const url = await getDownloadURL(snapshot.ref);
//             setImageCallback(url);
//         };

//         await handleRetry(uploadTask);
//         setIsUploadingCallback(false);
//     } catch (error) {
//         console.error("Error uploading file:", error);
//         setErrorMessage("Failed to upload the file. Please try again.");
//         setIsUploadingCallback(false);
//     }
// };




  const setApplierDataToFirestore = async (e) => {

    // Upload the file and get the URL
    // const fileUrl = fileValue ? await handleFileUpload(fileValue) : null;

    e.preventDefault();
    try {
      await addDoc(collection(db, "applier_Form"), {
        name: nameValue,
        phone: phoneValue,
        birthDate: birthDate,
        birthPlace: birthPlace,
        currentProfession: currentProfession,
        residence: residence,
        nationalId: nationalId,
        passportNumber: passportNumber,
        maritalStatus: maritalStatus,
        religion: religion,
        education: education,
        specialization: specialization,
        graduationInstitution: graduationInstitution,
        graduationYear: graduationYear,
        licenseValue: licenseValue,
        licenseTypeValue: licenseTypeValue,
        // fileValue: fileValue,

      });
      // setShowPopup(true);
      

      setNameValue('');
      setPhoneValue('');
      setBirthDate("");
      setBirthPlace("");
      setCurrentProfession("");
      setResidence("");
      setNationalId("");
      setPassportNumber("");
      setMaritalStatus("");
      setReligion("");
      setEducation("");
      setSpecialization("");
      setGraduationInstitution("");
      setGraduationYear("");
      setLicenseValue("");
      setLicenseTypeValue("");
      // setFile(null);

      
      setPopupType('success');
    } catch (error) {
      console.error("Error: ", error);
      setPopupType('failure');
    }
    setShowPopup(true);


    
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // const [license, setLicense] = useState(null);
  // const [licenseType, setLicenseType] = useState(null);

  // const handleLicenseChange = (event) => {
  //   setLicense(event.target.value);
  //   if (event.target.value !== "yes") {
  //     setLicenseType(null);
  //   }


  // };

  const handleLicenseChange = (e) => {
    setLicenseValue(e.target.value);
    // console.log('License Change:', e.target.value);  // Log to verify the value change
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("Form submitted");
  // };

  

  return (
    <div className="container my-5 form-container">
      <h2 className="text-center mb-4">انشاء سيرتك الذاتية</h2>
      <form dir="rtl"
        method="POST"
        // className="contact-form"
        onSubmit={setApplierDataToFirestore}>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>الاسم بالكامل</label>
            <input type="text" className="form-control"
              name="name"
              value={nameValue}
              onChange={(e) => setNameValue(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>رقم الهاتف</label>
            <input type="tel" className="form-control"
              name="phone"
              value={phoneValue}
              onChange={(e) => setPhoneValue(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>تاريخ الميلاد</label>
            <input type="date" className="form-control"
              name="birthDate"
              value={birthDate} onChange={(e) => setBirthDate(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>محل الميلاد</label>
            <input type="text" className="form-control"
              name="birthPlace"
              value={birthPlace} onChange={(e) => setBirthPlace(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>المهنة الحالية بجواز السفر</label>
            <input type="text" className="form-control"
              name="currentProfession"
              value={currentProfession} onChange={(e) => setCurrentProfession(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>محل الاقامة</label>
            <input type="text" className="form-control"
              name="residence"
              value={residence} onChange={(e) => setResidence(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>الرقم القومى</label>
            <input type="text" className="form-control"
              name="nationalId"
              value={nationalId} onChange={(e) => setNationalId(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>رقم جواز السفر</label>
            <input type="text" className="form-control"
              name="passportNumber"
              value={passportNumber} onChange={(e) => setPassportNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <label>الحالة الاجتماعية</label>
            <div className="d-flex flex-column">
              <label>
                <input
                  type="radio"
                  name="maritalStatus"
                  value="single"
                  checked={maritalStatus === "single"}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                />{" "}
                أعزب
              </label>
              <label>
                <input
                  type="radio"
                  name="maritalStatus"
                  value="married"
                  checked={maritalStatus === "married"}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                />{" "}
                متزوج
              </label>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label>الديانة</label>
            <div className="d-flex flex-column">
              <label>
                <input
                  type="radio"
                  name="religion"
                  value="Muslim"
                  checked={religion === "Muslim"}
                  onChange={(e) => setReligion(e.target.value)}
                />{" "}
                مسلم
              </label>
              <label>
                <input
                  type="radio"
                  name="religion"
                  value="Christian"
                  checked={religion === "Christian"}
                  onChange={(e) => setReligion(e.target.value)}
                />{" "}
                مسيحي
              </label>
            </div>
          </div>
        </div>


        <div className="form-row row">
          <div className="form-group col-md-3">
            <label>المؤهل الدراسى</label>
            <input type="text" className="form-control"
              name="education"
              value={education} onChange={(e) => setEducation(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>التخصص</label>
            <input type="text" className="form-control"
              name="specialization"
              value={specialization} onChange={(e) => setSpecialization(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>جهة التخرج</label>
            <input type="text" className="form-control"
              name="graduationInstitution"
              value={graduationInstitution}
              onChange={(e) => setGraduationInstitution(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>سنة التخرج</label>
            <input type="number" className="form-control"
              name="graduationYear"
              value={graduationYear}
              onChange={(e) => setGraduationYear(e.target.value)} />
          </div>
        </div>


        <div className="form-row row">
          {/* License Radio Group */}
          <div className="form-group col-md-12">
            <label>هل لديك رخصة؟</label>
            <div className="d-flex flex-column ml-3">
              <label>
                <input
                  type="radio"
                  name="license"
                  value="yes"
                  checked={licenseValue === "yes"}
                  onChange={handleLicenseChange}
                />{" "}
                نعم
              </label>
              <label>
                <input
                  type="radio"
                  name="license"
                  value="no"
                  checked={licenseValue === "no"}
                  onChange={handleLicenseChange}

                />{" "}
                لا
              </label>
            </div>
          </div>

          {/* License Type Radio Group (Conditionally Rendered) */}
          {licenseValue === "yes" && (
            <div className="form-group col-md-12">
              <label>نوع الرخصة</label>
              <div className="d-flex flex-column ml-3">
                <label>
                  <input
                    type="radio"
                    name="licenseType"
                    value="private"
                    checked={licenseTypeValue === "private"}
                    onChange={handleLicenseTypeChange}
                  />{" "}
                  خاصة
                </label>
                <label>
                  <input
                    type="radio"
                    name="licenseType"
                    value="first-class"
                    checked={licenseTypeValue === "first-class"}
                    // onChange={(e) => setLicenseType(e.target.value)}
                    // checked={licenseType === "first-class"}
                    onChange={handleLicenseTypeChange}
                  />{" "}
                  درجة أولى
                </label>
                <label>
                  <input
                    type="radio"
                    name="licenseType"
                    value="second-class"
                    checked={licenseTypeValue === "second-class"}
                    onChange={handleLicenseTypeChange}
                  />{" "}
                  درجة ثانية
                </label>
                <label>
                  <input
                    type="radio"
                    name="licenseType"
                    value="third-class"
                    checked={licenseTypeValue === "third-class"}
                    onChange={handleLicenseTypeChange}
                  />{" "}
                  درجة ثالثة
                </label>
              </div>
            </div>
          )}
        </div>



        {/* <div className="form-row row">
          <div className="form-group col-md-12">
            <label htmlFor="fileInput" className="btn btn-file">
              اختر الملف
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              // onChange={handleFileChange}
              className="form-control-file"
              style={{ display: "none" }}


              // onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
        </div> */}

        <div className="form-row row">
          <div className="col-md-3 d-flex justify-content-center">
            <button type="submit" className="btn submit-btn">
              ارسل طلبك
              <IoChevronBackOutline />

            </button>
          </div>
        </div>

      </form>
      {showPopup && (
        <div className="popup" onClick={handleClosePopup}>
          <div
            className={`popup-content ${popupType === 'success' ? 'popup-success' : 'popup-failure'}`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
          >
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <p>{popupType === 'success' ? 'تم ارسال البيانات بنجاح!' : 'فشل في إرسال البيانات!'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplierForm;
