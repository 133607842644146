import React,{useState, useEffect} from 'react'
import './AboutUs.css'
import img1 from '../../assets/happy-businessman-satisfied-with-deal.jpg'

function AboutUs() {

    const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsResponsive(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='container'>
            <section className='aboutUs'>
              

                
                {isResponsive ? (
                <>
                   <div className='left-aboutUs'>
                    <div className='about-us'>
                        <h2>شركة الأهرام لتوظيف المصريين بالخارج
                        </h2>
                        <div className='title__line'></div>
                        <p>
                            هى شركة رائدة و متميزة فى مجال توظيف المصريين بالخارج اكثر من 30 سنه خبره ، شركة مرخصة من وزارة القوى العاملةالضمرية و الهجرة بترخيص رقم 128.
                            متخصصة فى توفير  الايدى العاملة المدربة - المتخصصة  فى جميع المجالات :طبى , هندسي , تعيلمى ، مقاولات , صناعى, تجارى , تقنيى، محاسبى، إدارى، وغيرها
                            وتحظى الشركة بسمعة طيبة منذ أكثر من ثلاثون عاما حيث تم تأسيسها عام
                         </p>
                    </div>
                </div>
                  <div className='right-aboutUs'>
                    <img src={img1} />
                </div>
             

                </>
                ) : (
                <>
                  <div className='right-aboutUs'>
                    <img src={img1} />
                </div>
                <div className='left-aboutUs'>
                    <div className='about-us'>
                    <h2>شركة الأهرام لتوظيف المصريين بالخارج
                            </h2>
                        <div className='title__line'></div>
                        <p>
                            هى شركة رائدة و متميزة فى مجال توظيف المصريين بالخارج اكثر من 30 سنه خبره ، شركة مرخصة من وزارة القوى العاملةالضمرية و الهجرة بترخيص رقم 128.
                            متخصصة فى توفير  الايدى العاملة المدربة - المتخصصة  فى جميع المجالات :طبى , هندسي , تعيلمى ، مقاولات , صناعى, تجارى , تقنيى، محاسبى، إدارى، وغيرها
                            وتحظى الشركة بسمعة طيبة منذ أكثر من ثلاثون عاما حيث تم تأسيسها عام
                         </p>
                    </div>
                </div>
        
                </>

                )}
            </section>
        </div>
    )
}

export default AboutUs