import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './Partners.css'; 
import { IoChevronBackOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

import img1 from '../../assets/companies/قطاع المقاولات/آل طاوى.png';
import img2 from '../../assets/companies/قطاع المقاولات/آل مترى.png';
import img3 from '../../assets/companies/قطاع المقاولات/ابناء صالح الجهنى.png';
import img4 from '../../assets/companies/قطاع المقاولات/الأومير.jpg';
import img5 from '../../assets/companies/قطاع المقاولات/الاداء المتوازن.png';
import img6 from '../../assets/companies/قطاع المقاولات/الجبال الشمالية.jpg';
import img7 from '../../assets/companies/قطاع المقاولات/الجناحين.png';
import img8 from '../../assets/companies/قطاع المقاولات/الدهامى.png';
import img9 from '../../assets/companies/قطاع المقاولات/الديار القطرية.jpg';
import img10 from '../../assets/companies/قطاع المقاولات/الراجحى.png';
import img11 from '../../assets/companies/قطاع المقاولات/الراشد.png';
import img12 from '../../assets/companies/قطاع المقاولات/هوزان الجزيرة.jpg';
import img13 from '../../assets/companies/قطاع المقاولات/مشاريع الجزيرة.jpg';
import img14 from '../../assets/companies/قطاع المقاولات/مستورة.jpg';

const images = [
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14
];

const Partners = () => {
  return (
    <div className="partners">
      <div className="container">
        <h1>شركائنا</h1>
        <div className="line"></div>

        <div className="partners-slider">
          <Swiper
                modules={[Navigation, Autoplay]}
                spaceBetween={15}  
                slidesPerView={5}  
                navigation         
                autoplay={{ delay: 2000 }}
                loop={true}        
                className="mySwiper"
                grabCursor={true}   
                touchRatio={1}      
                touchStartPreventDefault={false}  
          >

          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`Partner ${index + 1}`}  className='img-slider'/>
            </SwiperSlide>
          ))}
          </Swiper>

          <div className="btn-cont">
            <Link className="know btn" to='/partners'>
            <IoChevronBackOutline />
              تعرف على عملائنا
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
