import React from 'react'
import './HeaderOwner.css'

function HeaderOwner() {
    return (
        <section className="hero5_container">
        <div className="container">
            <h1>
                صاحب العمل
            </h1>
            <div className='line'>
                
            </div>
        </div>
    </section>
    )
}

export default HeaderOwner
