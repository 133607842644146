import React from 'react'
import './HeaderContact.css'

function HeaderContact() {
    return (
        <section className="hero3_container">
            <div className="container">
                <h1>
                    تواصل معنا
                </h1>
                <div className='line'>
                    
                </div>
            </div>
        </section>
    )
}

export default HeaderContact
