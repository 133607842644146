import React, { useState } from 'react'
import './OwnerForm.css'
import { IoChevronBackOutline } from "react-icons/io5";

import '../../firebase';
import { addDoc, collection, getFirestore } from 'firebase/firestore';

function OwnerForm() {

  const [inputvalue5, setInputvalue5] = useState('');
  const [inputvalue6, setInputvalue6] = useState('');
  const [inputvalue7, setInputvalue7] = useState('');
  const [inputvalue8, setInputvalue8] = useState('');
  const [inputvalue9, setInputvalue9] = useState('');

  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState('success'); // Can be 'success' or 'failure'


  const db = getFirestore();
  const setOwnerDataToFirestore = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Owner_Form"), {
        owner_name: inputvalue5,
        owner_mail: inputvalue6,
        owner_phone: inputvalue7,
        job_title: inputvalue8,
        job_description: inputvalue9,
      });
      setShowPopup(true);
      setInputvalue5('');
      setInputvalue6('');
      setInputvalue7('');
      setInputvalue8('');
      setInputvalue9('');


      setPopupType('success');
    } catch (error) {
      console.error("Error: ", error);
      setPopupType('failure');
    }
    setShowPopup(true);

  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     console.log("Form submitted");
  //   };


  return (
    <div className="container my-5 form-container">
      <form dir="rtl"
        onSubmit={setOwnerDataToFirestore}
        method='POST'

      >
        <div className="form-row row">
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder='الاسم بالكامل'
              name='owner_name'
              value={inputvalue5}
              onChange={(e) => setInputvalue5(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <input type="email" className="form-control" placeholder='البريد الالكترونى'
              name='owner_mail'
              value={inputvalue6}
              onChange={(e) => setInputvalue6(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <input type="tel" className="form-control" placeholder='رقم الهاتف للتواصل' dir='rtl'
              name='owner_phone'
              value={inputvalue7}
              onChange={(e) => setInputvalue7(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder='المسمى الوظيفى'
              name='job_title'
              value={inputvalue8}
              onChange={(e) => setInputvalue8(e.target.value)}
              required />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-12">
            <textarea className="form-control" cols={5} rows={10} placeholder='وصف الوظيفة'
             name='job_description'
             value={inputvalue9}
             onChange={(e) => setInputvalue9(e.target.value)}
             required
             />
          </div>
        </div>

        <div className="form-row row">
          <div className="col-md-3 d-flex justify-content-center">
            <button type="submit" className="btn submit-btn">
              ارسل طلبك
              <IoChevronBackOutline />
            </button>
          </div>
        </div>
      </form>

      {showPopup && (
        <div className="popup" onClick={handleClosePopup}>
          <div
            className={`popup-content ${popupType === 'success' ? 'popup-success' : 'popup-failure'}`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
          >
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <p>{popupType === 'success' ? 'تم ارسال البيانات بنجاح!' : 'فشل في إرسال البيانات!'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnerForm
