import React from 'react';
import './Statics.css'; 

const Statics = () => {
  return (
    <div className="statics">
      <div className="statics-num">
        <div className="all">
          <div className="item">
            <p className="num">+14 k</p>
            <p className="txt">وظيفة متاحة</p>
          </div>
          <div className="item">
            <p className="num">+18 k</p>
            <p className="txt">سيرة ذاتية</p>
          </div>
          <div className="item">
            <p className="num">+9 k</p>
            <p className="txt">شركة</p>
          </div>
          <div className="item">
            <p className="num">+34</p>
            <p className="txt">تم تعينه</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statics;