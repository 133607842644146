import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa'; 
import './SocialBtns.css'; 

const SocialBtns = () => {
  return (
    <div className="social-btns">
      <a href="https://www.facebook.com/groups/467433135229688/?ref=share&mibextid=KtfwRi" target="_blank" rel="noopener noreferrer" className='facebook-btn'>
        <FaFacebookF />
      </a>
      <a href="http://wa.me/+201016688751" target="_blank" rel="noopener noreferrer" className='whatsapp-btn'>
        <FaWhatsapp />
      </a>
      {/* <a href="" target="_blank" rel="noopener noreferrer" className='linkedin-btn'>
        <FaLinkedinIn />
      </a> */}
    </div>
  );
};

export default SocialBtns;