import React from 'react'
import Nav from '../Nav'
import SocialBtns from '../SocialBtns'
import Footer from '../Footer'
import HeaderOwner from './HeaderOwner'
import OwnerForm from './OwnerForm'

function Owner() {
    return (
        <>
            <Nav />
            <HeaderOwner />
            <SocialBtns />
            <OwnerForm />
            <Footer />
        </>
    )
}

export default Owner
