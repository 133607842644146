import React from 'react'
import Nav from '../Nav'
import HeaderDetails from './HeaderDetails'
import Details from './Details'
import Footer from '../Footer'
import SocialBtns from '../SocialBtns'

function JobDetails() {
    return (
        <>
            <Nav />
            <HeaderDetails />
            <SocialBtns />
            <Details />
            <Footer />
        </>
    )
}

export default JobDetails
