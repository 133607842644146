import React from 'react'
import './HeaderApplier.css'

function HeaderApplier() {
    return (
        <section className="hero6_container">
        <div className="container">
            <h1>
                باحث عن وظيفة
            </h1>
            <div className='line'>
                
            </div>
        </div>
    </section>
    )
}

export default HeaderApplier
