import React from 'react'
import './HeaderPartners.css'

function HeaderPartners() {
  return (
    <section className="hero2_container">
      <div className="container">
        <h1>
          الشركاء
        </h1>
        <div className='line'></div>
      </div>
    </section>
  )
}

export default HeaderPartners

