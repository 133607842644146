import React, { useState } from 'react';
import './LatestJobs.css'; 
import { IoChevronBackOutline } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";

import { Link } from 'react-router-dom';

import architectImg from '../../assets/building-construction-worker-site.jpg';
import teacherImg from '../../assets/portrait-professor-work-educational-system.jpg';
import nurseImg from '../../assets/portrait-nurse-holding-clipboard.jpg';
import accountImg from '../../assets/checking-workplace-table-counting-man.jpg'

const jobsData = [
  { title: 'مهندس معماري', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
  { title: 'مدرسين', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: teacherImg },
  { title: 'ممرضات', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: nurseImg },
  { title: 'محاسب', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية' ,  img: accountImg },
  { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
  { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
  { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg },
  { title: 'مهندس ', desc1: 'مطلوب للعمل في المملكة العربية السعودية في إحدى الشركات المعمارية', img: architectImg }
];

const itemsPerPage = 4;

const LatestJobs = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(jobsData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const renderJobs = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return jobsData.slice(start, end).map((job, index) => (
      <div className="col-lg-6" key={index}>
        <div className="jobCard">
          <img src={job.img}  className="img-fluid job-img" />
          {/* <div className="separator-line"></div> */}
          <div className="job-details">
            <h5 className="job-title">{job.title}</h5>
            <p>{job.desc1}</p>
            <p>{job.desc2}</p>
            <Link to='/details' className="read-details">
            اقرأ التفاصيل
            <IoChevronBackOutline />
            
            </Link>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <section className="latest-jobs-section" dir='rtl'>
      <div className="container">
        {/* Section Header */}
        <h2>أحدث الوظائف</h2>
        <div className="title-line"></div>

        {/* Job Cards */}
        <div className="row" id="jobCardsContainer">
          {renderJobs()}
        </div>

        {/* Pagination */}
        <div className="pagination-container">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={handlePrevPage}> 
              <IoChevronForward />
              </button>
            </li>
            {[...Array(Math.ceil(jobsData.length / itemsPerPage)).keys()].map(page => (
              <li key={page + 1} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageClick(page + 1)}>{page + 1}</button>
              </li>
            ))}
            <li className={`page-item ${currentPage === Math.ceil(jobsData.length / itemsPerPage) ? 'disabled' : ''}`}>
              <button className="page-link" onClick={handleNextPage}> 
              <IoChevronBackOutline />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default LatestJobs;