import React from 'react';
import './Header.css'; 
import { IoChevronBackOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <section className="hero_container">
      <div className="container">
        <div className="content_container">
          <h1>
            شركة <span> الأهـــــــــرام</span>
          </h1>
          <h3>للتوظيف وإلحاق العمالة بالخارج</h3>
          <p>للحصول على الوظيفة التي تناسبك بكل سهولة</p>
          <p>كل اللي عليك ملئ بياناتك</p>

          <div className="hero-btns">
              <Link to='./Owner' className='btn btn-custom owner'>
              <IoChevronBackOutline />
              صاحب العمل 
              </Link>
              <Link to='/applier' className='btn btn-custom applier'>
              <IoChevronBackOutline /> 
               باحث عن وظيفة
               </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;