import React from 'react'
import './DownloadProfile.css'

function DownloadProfile() {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/files/شركه الاهرام للتوظيف بالخارج ترخيص128.pdf'; 
        link.download = 'شركه الاهرام للتوظيف بالخارج ترخيص128.pdf'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
      };
    
    return (
        <section className='downlaod-file'>
            <p>
            شركة الاهرام لإلحاق العمالة المصرية بالخارج ترخيص رقم 128 إحدى
            <br />
             شركات التوظيف الرائدة في جمهورية مصر العربية
            </p>
            <button onClick={handleDownload} className="download">
                تحميل بروفايل الشركة
            </button>
        </section>
    )
}

export default DownloadProfile
