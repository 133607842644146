import React from 'react'
import Nav from '../Nav'
import Footer from '../Footer'
import ContactForm from './ContactForm'
import HeaderContact from './HeaderContact'

function Contact() {
    return (
        <>
            <Nav />
            <HeaderContact />
            <ContactForm />
            <Footer />
        </>
    )
}

export default Contact
